// Fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&family=Open+Sans&display=swap');

// Variables
@import './bootstrap/variables';

// Bootstrap
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/code';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import "./bootstrap/buttons";
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/jumbotron';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/media';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import "./bootstrap/modal";
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import "./bootstrap/carousel";
@import '~bootstrap/scss/spinners';
@import "~bootstrap/scss/utilities/align";
@import "~bootstrap/scss/utilities/background";
@import "~bootstrap/scss/utilities/borders";
@import "~bootstrap/scss/utilities/clearfix";
@import "~bootstrap/scss/utilities/display";
@import "./bootstrap/utilities/ratios";
@import "~bootstrap/scss/utilities/flex";
@import "~bootstrap/scss/utilities/float";
@import "~bootstrap/scss/utilities/interactions";
@import "~bootstrap/scss/utilities/overflow";
@import "~bootstrap/scss/utilities/position";
@import "~bootstrap/scss/utilities/screenreaders";
@import "~bootstrap/scss/utilities/shadows";
@import "./bootstrap/utilities/sizing";
@import "~bootstrap/scss/utilities/spacing";
@import "~bootstrap/scss/utilities/stretched-link";
@import "~bootstrap/scss/utilities/text";
@import "~bootstrap/scss/utilities/visibility";
@import '~bootstrap/scss/print';

// HTML
html {
    font-size: 16px;
    font-size: 4vw;
    @include media-breakpoint-up(sm) {
        font-size: 2.5vw;
    }
    @include media-breakpoint-up(md) {
        font-size: 2.5vw;
    }
    @include media-breakpoint-up(lg) {
        font-size: 1.5vw;
    }
    @include media-breakpoint-up(xl) {
        font-size: 16px;
    }
}

// Body
body {
    padding-top: 4rem;
    &.overlay-menu-visible {
        overflow: hidden;
        @include media-breakpoint-up(lg) {
            overflow: auto;
        }
    }
}

// Header
header {
    position: fixed;
    z-index: 22;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
    padding: $spacer 0;
    background: rgba(map-get($colors, 'white'), .95);
    @include box-shadow(0 .25rem .5rem .1rem rgba($black, .25));
    color: map-get($colors, 'white');
    .branding {
        a {
            position: relative;
            display: flex;
            justify-content: start;
            align-items: center;
            color: $primary;
            text-decoration: none;
            text-transform: uppercase;
            line-height: 1;
            img {
                display: block;
                width: auto;
                height: 2rem;
            }
            .name {
                display: none;
                font-size: 1.25rem;
                font-weight: bold;
                line-height: 1.25rem;
                white-space: nowrap;
                margin-left: ($spacer / 2);
                @include media-breakpoint-up(lg) {
                    display: block;
                }
            }
            .abbreviation {
                font-size: 1.5rem;
                font-weight: bold;
                line-height: 1.5rem;
                white-space: nowrap;
                margin-left: ($spacer / 2);
                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }
        }
    }
    .desktop-menu {
        display: none;
        @include media-breakpoint-up(lg) {
            display: flex;
            justify-items: flex-start;
            align-items: center;
        }
        a {
            &.menu-item {
                color: $primary;
                text-decoration: none;
                margin-left: $spacer;
                &:first-child {
                    margin-left: 0;
                }
                @include hover() {
                    color: lighten($primary, 15%);
                }
            }
        }
        a.btn {
            margin-left: $spacer;
        }
    }
    .overlay-menu-toggle {
        color: $primary;
        text-decoration: none;
        font-size: 1.5rem;
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}

// Overlay menu
#overlay-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 21;
    top: 0;
    right: auto;
    bottom: 0;
    left: 100%;
    width: 100vw;
    height: 100vh;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
    padding: $spacer;
    padding-top: ($spacer * 5);
    background: rgba(map-get($theme-colors, 'primary'), 1);
    color: $white;
    text-align: center;
    @include media-breakpoint-up(lg) {
        display: none;
    }
    &.visible {
        left: 0;
    }
    a {
        color: $white;
        text-decoration: none;
    }
    .menu-item {
        margin-top: $spacer;
        &:first-child {
            margin-top: 0;
        }
        a {
            font-size: 1.5rem;
        }
    }
}

// Sections
section {
    &.section {
        margin: 0;
        padding: 0;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        &.section-default {
            padding: $spacer 0;
            .section-title {
                font-family: 'Montserrat', sans-serif;
                font-size: 1.75rem;
                text-transform: uppercase;
                margin-bottom: $spacer;
                @include media-breakpoint-up(sm) {
                    font-size: 2rem;
                }
                @include media-breakpoint-up(lg) {
                    font-size: 2.5rem;
                }
            }
        }
        &.section-odd {
            background-color: $white;
        }
        &.section-even {
            background-color: $light;
            background-image: radial-gradient(lighten($light, 5%), darken($light, 5%));
        }
        &.section-cover,
        &.section-halfcover {
            padding: 0;
            width: 100vw;
            height: 100vh;
            color: $white;
            text-align: center;
            text-shadow: 0 0 1px map-get($theme-colors, 'dark'),
                1px 1px 1px rgba(map-get($theme-colors, 'dark'), .5),
                -1px -1px 1px rgba(map-get($theme-colors, 'dark'), .5),
                -1px 1px 1px rgba(map-get($theme-colors, 'dark'), .5),
                1px -1px 1px rgba(map-get($theme-colors, 'dark'), .5),
                2px 2px 1px rgba(map-get($theme-colors, 'dark'), .5),
                3px 3px 1px rgba(map-get($theme-colors, 'dark'), .4),
                4px 4px 1px rgba(map-get($theme-colors, 'dark'), .3),
                5px 5px 1px rgba(map-get($theme-colors, 'dark'), .2),
                6px 6px 1px rgba(map-get($theme-colors, 'dark'), .1);
            a {
                color: $white;
            }
        }
        &.section-hero {
            height: auto;
            min-height: 50vh;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: map-get($theme-colors, 'primary');
            border-bottom: 1px solid $border-color;
            color: $white;
            .hero-title {
                font-family: 'Montserrat', sans-serif;
                font-size: 2rem;
                line-height: 2rem;
                text-align: center;
                text-shadow: 0 0 1px map-get($theme-colors, 'dark'),
                    1px 1px 1px rgba(map-get($theme-colors, 'dark'), .5),
                    -1px -1px 1px rgba(map-get($theme-colors, 'dark'), .5),
                    -1px 1px 1px rgba(map-get($theme-colors, 'dark'), .5),
                    1px -1px 1px rgba(map-get($theme-colors, 'dark'), .5),
                    2px 2px 1px rgba(map-get($theme-colors, 'dark'), .5),
                    3px 3px 1px rgba(map-get($theme-colors, 'dark'), .4),
                    4px 4px 1px rgba(map-get($theme-colors, 'dark'), .3),
                    5px 5px 1px rgba(map-get($theme-colors, 'dark'), .2),
                    6px 6px 1px rgba(map-get($theme-colors, 'dark'), .1);
                @include media-breakpoint-up(lg) {
                    font-size: 3rem;
                    line-height: 3rem;
                }
            }
            .hero-subtitle {
                margin-top: 1rem;
                font-size: 1.5rem;
                line-height: 1.5rem;
                text-align: center;
                text-shadow: 0 0 1px map-get($theme-colors, 'dark'),
                    1px 1px 1px rgba(map-get($theme-colors, 'dark'), .5),
                    -1px -1px 1px rgba(map-get($theme-colors, 'dark'), .5),
                    -1px 1px 1px rgba(map-get($theme-colors, 'dark'), .5),
                    1px -1px 1px rgba(map-get($theme-colors, 'dark'), .5),
                    2px 2px 1px rgba(map-get($theme-colors, 'dark'), .5),
                    3px 3px 1px rgba(map-get($theme-colors, 'dark'), .4),
                    4px 4px 1px rgba(map-get($theme-colors, 'dark'), .3),
                    5px 5px 1px rgba(map-get($theme-colors, 'dark'), .2),
                    6px 6px 1px rgba(map-get($theme-colors, 'dark'), .1);
                @include media-breakpoint-up(lg) {
                    font-size: 2rem;
                    line-height: 2rem;
                }
            }
        }
        &.section-carousel {
            .carousel-item {
                height: 100vw;
                background-size: cover;
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-color: $primary;
                @include media-breakpoint-up(md) {
                    height: 75vw;
                }
                @include media-breakpoint-up(lg) {
                    height: 50vw;
                }
                @include media-breakpoint-up(xl) {
                    height: 33.333333vw;
                }
                .carousel-image {
                    display: none;
                    width: 100%;
                    height: auto;
                }
                .carousel-content {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: rgba($black, .25);
                    font-family: 'Montserrat', sans-serif;
                    text-transform: uppercase;
                    color: $white;
                    text-shadow: 0 0 1px map-get($theme-colors, 'dark'),
                        1px 1px 1px rgba(map-get($theme-colors, 'dark'), .5),
                        -1px -1px 1px rgba(map-get($theme-colors, 'dark'), .5),
                        -1px 1px 1px rgba(map-get($theme-colors, 'dark'), .5),
                        1px -1px 1px rgba(map-get($theme-colors, 'dark'), .5),
                        2px 2px 1px rgba(map-get($theme-colors, 'dark'), .5),
                        3px 3px 1px rgba(map-get($theme-colors, 'dark'), .4),
                        4px 4px 1px rgba(map-get($theme-colors, 'dark'), .3),
                        5px 5px 1px rgba(map-get($theme-colors, 'dark'), .2),
                        6px 6px 1px rgba(map-get($theme-colors, 'dark'), .1);
                    .carousel-title {
                        font-weight: 600;
                        font-size: 2.5rem;
                        text-align: center;
                        @include media-breakpoint-up(sm) {
                            font-size: 4rem;
                        }
                        @include media-breakpoint-up(md) {
                            font-size: 3.5rem;
                        }
                        @include media-breakpoint-up(lg) {
                            font-size: 4rem;
                        }
                    }
                    .carousel-subtitle {
                        font-weight: 500;
                        margin-top: 1rem;
                        font-size: 1.25rem;
                        text-align: center;
                        @include media-breakpoint-up(sm) {
                            font-size: 2rem;
                        }
                        @include media-breakpoint-up(md) {
                            font-size: 1.75rem;
                        }
                        @include media-breakpoint-up(lg) {
                            font-size: 2rem;
                        }
                    }
                }
            }
        }
    }
}

// Alternate fonts
.altfont1 {
    font-family: 'Montserrat', sans-serif;
}

// Background cover
.bg-cover {
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

// Accordion
.collapsible-group {
    position: relative;
    margin-top: 1rem;
    @include border-radius();
    @include box-shadow($box-shadow);
    overflow: hidden;
    &:first-child {
        margin-top: 0;
    }
    &.collapsed {
        .collapsible-header {
            background-color: $light;
            color: $primary;
            @include hover() {
                background-color: $primary;
                color: $white;
            }
            .svg-inline--fa {
                font-size: 1rem;
                &:first-child {
                    display: block;
                }
                &:last-child {
                    display: none;
                }
            }
        }
    }
    .collapsible-header {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1;
        background-color: $primary;
        color: $white;
        .svg-inline--fa {
            &:first-child {
                display: none;
            }
            &:last-child {
                display: block;
            }
        }
    }
    .collapsible-content {
        position: relative;
        background: $light;
        padding: 1rem;
    }
}

// Unordered lists
ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
        position: relative;
        margin: .5rem 0 0 0;
        padding: 0 0 0 1rem;
        &:first-child {
            margin-top: 0;
        }
        &::before {
            content: "\2022";
            position: absolute;
            top: 0;
            left: 0;
            color: $primary;
        }
    }
}

// Define a variable to append to assets to prevent caching when compoiled
$cacheBuster: 'ae7aa4f4';

// Define the banner images/dimensions
$bannerImageKeys: (
    '8a106afe',
    'ce29615c',
    '3c7d7885',
    '9edb9447',
);
$bannerImageDimensions: (
    xs: '576x576',
    sm: '768x768',
    md: '992x744',
    lg: '1200x600',
    xl: '1920x640',
);

// Define the membership images/dimensions
$membershipImageKeys: (
    'ce29615c',
    '8a106afe',
    '106b53bf',
    '12e2cc99',
);
$membershipImageDimensions: (
    xs: '576x288',
    sm: '576x288',
    md: '256x256',
    lg: '256x256',
    xl: '256x256',
);

// Define the subscription images/dimensions
$subscriptionImageKeys: (
    '59c442de',
    '6d7f98ae',
    'dd31cd7b',
    'af1b8fbc',
);
$subscriptionImageDimensions: (
    xs: '576x288',
    sm: '768x384',
    md: '992x496',
    lg: '288x288',
    xl: '288x288',
);

// Generate the images for the membership/subscription sections
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        @each $ImageKey in $bannerImageKeys {
            #banner-image-#{$ImageKey} {
                background-image: url('/img/banner-#{$ImageKey}-#{map-get($bannerImageDimensions, $breakpoint)}.jpg?#{$cacheBuster}');
            }
        }
        @each $ImageKey in $membershipImageKeys {
            #membership-image-#{$ImageKey} {
                background-image: url('/img/membership-#{$ImageKey}-#{map-get($membershipImageDimensions, $breakpoint)}.jpg?#{$cacheBuster}');
            }
        }
        @each $ImageKey in $subscriptionImageKeys {
            #subscription-image-#{$ImageKey} {
                background-image: url('/img/subscription-#{$ImageKey}-#{map-get($subscriptionImageDimensions, $breakpoint)}.jpg?#{$cacheBuster}');
            }
        }
    }
}
