// Colors
$white: #fff;
$black: #000;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

// Colors map
$colors: (
    'black': $black,
    'white': $white,
    'blue' : #007bff,
    'indigo' : #6610f2,
    'purple' : #6f42c1,
    'pink' : #e83e8c,
    'red' : #dc3545,
    'orange' : #fd7e14,
    'yellow' : #ffc107,
    'green' : #28a745,
    'teal' : #20c997,
    'cyan' : #17a2b8,
    'gray': $gray-500,
    'darkgray': $gray-800,
    'lightgray': $gray-200
);

// Theme colors
$primary: #006bb7;
$secondary: #61a7d8;
$success: map-get($colors, 'green');
$info: map-get($colors, 'cyan');
$warning: map-get($colors, 'orange');
$danger: map-get($colors, 'red');
$light: #eaf6ff;
$dark: map-get($colors, 'darkgray');

// Theme colors map
$theme-colors: (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark
);

// Spacers
$spacer: 1rem;
$spacers: (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 2)
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
    auto: auto,
    5: 5%,
    10: 10%,
    15: 15%,
    20: 20%,
    25: 25%,
    30: 30%,
    35: 35%,
    40: 40%,
    45: 45%,
    50: 50%,
    60: 60%,
    75: 75%,
    100: 100%,
    1x: 1rem,
    1p25x: 1.25rem,
    1p5x: 1.5rem,
    2x: 2rem,
    2p5x: 2.5rem,
    3x: 3rem,
    4x: 4rem,
    6x: 6rem,
    8x: 8rem,
    12x: 12rem,
    16x: 16rem
);

// Max container widths
$container-max-widths: (
    xl: 1200px
);

// Grid columns
$grid-columns: 12;
$grid-gutter-width: ($spacer * 2);
$grid-row-columns: 6;

// Settings
$enable-caret: true;
$enable-rounded: true;
$enable-shadows: true;
$enable-gradients: false;
$enable-transitions: true;
$enable-prefers-reduced-motion-media-query: true;
$enable-grid-classes: true;
$enable-pointer-cursor-for-buttons: true;
$enable-print-styles: true;
$enable-responsive-font-sizes: false;
$enable-validation-icons: true;
$enable-deprecation-messages: true;

// Body colors
$body-bg: map-get($colors, 'white');
$body-color: map-get($colors, 'darkgray');

// Typography
$font-family-sans-serif: 'Open Sans', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.35;
$line-height-lg: $line-height-base;
$line-height-sm: $line-height-base;

// Links
$link-color: map-get($theme-colors, 'primary');
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;
$emphasized-link-hover-darken-percentage: 15%;

// Modal
$modal-backdrop-bg: $black;
$modal-backdrop-opacity: .75;

// YIQ threshold
$yiq-contrasted-threshold: 155;

// Shadows
$box-shadow-sm: 0 .125rem .25rem rgba($black, .075);
$box-shadow: 0 .25rem .5rem rgba($black, .25);
$box-shadow-lg: 0 1rem 2rem rgba($black, .175);

// Form grid
$form-grid-gutter-width: $spacer;

// Aspect ratios
$aspect-ratios: (
    (32 9),
    (21 9),
    (16 9),
    (4 3),
    (2 1),
    (1 1),
);
