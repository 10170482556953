.ratio {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  &::before {
    display: block;
    content: "";
  }
  .ratio-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
		@each $aspect-ratio in $aspect-ratios {
			$aspect-ratio-x: nth($aspect-ratio, 1);
			$aspect-ratio-y: nth($aspect-ratio, 2);
			.ratio#{$infix}-#{$aspect-ratio-x}x#{$aspect-ratio-y} {
				&::before {
					padding-top: percentage($aspect-ratio-y / $aspect-ratio-x);
				}
			}
		}
	}
}
